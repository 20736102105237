<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_billingAchievementAddUpdate" />
      <!-- 請求実績登録変更画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-text-field
                  dense
                  v-model="supplierNm"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                >
                </v-text-field>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 請求No(業務No) -->
              <div class="serch-textbox-arrivalNo" style="float: left; width: 450px">
                <v-text-field
                  dense
                  v-model="billingBussinessNoNm"
                  :label="$t('label.lbl_billingBussinessNo')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 請求月度-->
              <!-- カレンダー -->
              <!-- カレンダーfrom-->
              <div class="date-style" style="float: left; margin-left: 12px">
                <v-text-field
                  dense
                  v-model="billingMonth"
                  :label="$t('label.lbl_billingMonth')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                >
                </v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <div style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <!--合計金額領域-->
              <div style="float: left; display: flex; align-items: center; width: 60%">
                <div
                  style="
                    margin-left: 86.5%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <!-- 合計金額-->
                  {{ $t("label.lbl_totalSum") + " " + this.sumPriceTxt }}
                </div>
              </div>
              <div v-if="!newFlg && !editing && !errorFlg" style="float: right">
                <!-- 送信ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_send')"
                >
                  {{ $t("btn.btn_send") }}
                </v-btn>
                <!-- PDF出力ボタン-->
                <!--<v-btn ref="btnInquiry" color="primary" class="other-btn" @click="outputPdf">-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="other-btn"
                  @click="getInvoiceDetailsReport()"
                >
                  {{ $t("btn.btn_outputPdf") }}
                </v-btn>
              </div>
              <!--編集ボタン領域-->
              <div style="float: right">
                <!-- 編集ボタン-->
                <v-btn
                  v-if="!newFlg && !editing && !errorFlg"
                  color="primary"
                  class="api-btn"
                  @click="edit"
                >
                  {{ $t("btn.btn_edit") }}
                </v-btn>
              </div>
              <div v-if="editing" style="float: right">
                <!-- システム情報反映ボタン-->
                <v-btn
                  v-if="!newFlg"
                  ref="btnRefreshSystemData"
                  color="primary"
                  class="other-btn"
                  @click="refreshSystemData"
                  width="150px"
                >
                  {{ $t("btn.btn_updateSystemData") }}
                </v-btn>
                <!-- 行追加ボタン-->
                <v-btn ref="btnInquiry" color="primary" class="other-btn" @click="addRow">
                  {{ $t("btn.btn_rowsAdd") }}
                </v-btn>
                <!-- 完了ボタン-->
                <v-btn
                  ref="btnInquiry"
                  color="primary"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_complete')"
                >
                  {{ $t("btn.btn_complete") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-container fluid class="font-medium">
          <v-data-table
            id="listData"
            ref="listData"
            fixed-header
            :headers="shownHeaders"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="720px"
          >
            <!-- 請求項目 -->
            <template v-slot:[`item.billingItems`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ getBillingItemsText(item) }}
              </div>
              <div v-else id="table-billingItems" class="pull-min-width">
                <v-autocomplete
                  v-model="item.billingItems"
                  :items="billingItemList"
                  :rules="[rules.required]"
                  :hint="checkBillingItems(item)"
                  item-text="value"
                  persistent-hint
                  class="cursorProcd"
                  :id="'billingItems' + index"
                  @change="getSubjectList(item, index)"
                  dense
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 収入科目 -->
            <template v-slot:[`item.billingSubject`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ getBillingSubjectText(item) }}
              </div>
              <div v-else id="table-billingSubject" class="pull-min-width">
                <v-autocomplete
                  v-model="item.billingSubject"
                  :items="item.billingSubjectList"
                  :hint="checkBillingSubject(item)"
                  item-text="code"
                  :rules="[rules.required]"
                  persistent-hint
                  :id="'billingSubject' + index"
                  dense
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 単価区分 -->
            <template v-slot:[`item.unitPriceClassificPrice`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ getUnitPriceClassificPrice(item) }}
              </div>
              <div v-else id="table-unitPriceClassificPrice" class="pull-min-width">
                <v-autocomplete
                  v-model="item.unitPriceClassificPrice"
                  :rules="[rules.required]"
                  :items="unitPriceClassificPriceList"
                  item-text="value"
                  :hint="checkUnitPriceClassificPrice(item)"
                  persistent-hint
                  dense
                  :id="'unitPriceClassificPrice' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span>
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 単価内容 -->
            <template v-slot:[`item.unitPriceClassContents`]="{ item, value }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ item.unitPriceClassContents }}
              </div>
              <div v-else id="table-unitPriceClassContents">
                <v-text-field
                  outlined
                  :value="value"
                  :rules="[
                    rules.required,
                    rules.checkContents,
                    rules.isValidUnitPriceClassContents(
                      value.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/)
                    ),
                  ]"
                  :items="unitPriceClassContents"
                  dense
                  class="text-box"
                  @load="value = item.unitPriceClassContents"
                  @change="changeUnitPriceClassContentsTxt(item, $event)"
                  maxLength="22"
                >
                </v-text-field>
              </div>
            </template>

            <!-- 単価Gr -->
            <template v-slot:[`item.unitPriceGr`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-center">
                {{ getUnitPriceGrList(item) }}
              </div>
              <div v-else id="table-unitPriceGr" class="align-center pull-min-width">
                <v-autocomplete
                  v-model="item.unitPriceGr"
                  :items="unitPriceGrList"
                  :hint="checkUnitPriceGrList(item)"
                  item-text="value"
                  persistent-hint
                  dense
                  :id="'unitPriceGr' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 期 -->
            <template v-slot:[`item.period`]="{ item }">
              <div v-if="readOnlyCheck(2, item)" class="align-right">
                {{ getPeriod(item) }}
              </div>
            </template>

            <!-- 単価 -->
            <template v-slot:[`item.unitPrice`]="{ item, index, value }">
              <div v-if="readOnlyCheck(2, item)" class="align-right">
                {{ item.unitPrice }}
              </div>
              <div v-else id="table-unitPrice">
                <v-text-field
                  outlined
                  :value="value"
                  @change="changeUnitPriceTxt(item, $event, index)"
                  @load="value = item.unitPrice"
                  :rules="[
                    rules.required,
                    rules.isNumber,
                    rules.checkPriceValue,
                    checkMaxAmount(item.achieveQuantity, item.unitPrice, item.convertedValue),
                  ]"
                  class="text-box align-right"
                  maxlength="10"
                  dense
                >
                </v-text-field>
              </div>
            </template>

            <!-- 単位コード/単位名 -->
            <template v-slot:[`item.unitPriceCd`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-center">
                {{ getUnitPriceCd(item) }}
              </div>
              <div v-else id="table-unitPriceCd" class="align-center pull-min-width">
                <v-autocomplete
                  v-model="item.unitPriceCd"
                  :hint="checkUnitPriceCd(item)"
                  :rules="[rules.required]"
                  :items="unitPriceCdList"
                  item-text="value"
                  persistent-hint
                  dense
                  :id="'unitPriceCd' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 実績数量 -->
            <template v-slot:[`item.achieveQuantity`]="{ item, index, value }">
              <div v-if="readOnlyCheck(1, item, true)" class="align-right">
                {{ item.achieveQuantity }}
              </div>
              <div v-else id="table-achieveQuantity">
                <v-text-field
                  outlined
                  :value="value"
                  @load="value = item.achieveQuantity"
                  @change="changeAchieveQuantityTxt(item, $event, index)"
                  :items="achieveQuantity"
                  :rules="[
                    rules.required,
                    rules.isNumber,
                    rules.checkAchieveQuantity,
                    checkMaxAmount(item.achieveQuantity, item.unitPrice, item.convertedValue),
                  ]"
                  class="text-box align-right"
                  maxlength="9"
                  dense
                >
                </v-text-field>
              </div>
            </template>

            <!-- 金額 -->
            <template v-slot:[`item.price`]="{ item, index }">
              <div class="align-right">
                {{ getPrice(item, index) }}
              </div>
            </template>

            <!-- 単価係数 -->
            <template v-slot:[`item.convertedValue`]="{ item, value }">
              <div v-if="readOnlyCheck(2, item)" class="align-right">
                {{ toPlainValue(item.convertedValue) }}
              </div>
              <div v-else id="table-convertedValue">
                <v-text-field
                  outlined
                  :value="value"
                  :items="toPlainValue(convertedValue)"
                  @load="value = item.convertedValue"
                  @change="changeConvertedValueTxt(item, $event)"
                  maxlength="4"
                  class="text-box align-right"
                  :rules="[
                    rules.required,
                    rules.isNumber,
                    rules.isNonZero,
                    rules.checkConvertedValue,
                    checkMaxAmount(item.achieveQuantity, item.unitPrice, item.convertedValue),
                  ]"
                  dense
                >
                </v-text-field>
              </div>
            </template>

            <!-- 売上営業所 -->
            <template v-slot:[`item.officeSales`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ checkOfficeSales(item) }}
              </div>
              <div v-else id="table-officeSales" class="pull-min-width">
                <v-autocomplete
                  v-model="item.officeSales"
                  :items="item.officeSalesList"
                  :rules="[rules.required]"
                  persistent-hint
                  dense
                  :id="'officeSales' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 倉庫コード/倉庫名 -->
            <template v-slot:[`item.warehouseCd`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ checkWarehouseCd(item) }}
              </div>
              <div v-else id="table-warehouseCd" class="pull-min-width">
                <v-autocomplete
                  v-model="item.warehouseCd"
                  :items="item.warehouseList"
                  dense
                  :id="'warehouseCd' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 税区分 -->
            <template v-slot:[`item.taxClassification`]="{ item, index }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ getTaxClassification(item) }}
              </div>
              <div v-else id="table-taxClassification" class="pull-min-width">
                <v-autocomplete
                  v-model="item.taxClassification"
                  :items="taxClassificationList"
                  :rules="[rules.required]"
                  persistent-hint
                  dense
                  :id="'taxClassification' + index"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.name }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- 計算区分 -->
            <template v-slot:[`item.calculationClassification`]="{ item }">
              <div class="align-left">
                {{ getCalculationClassification(item) }}
              </div>
            </template>

            <!-- 振替率% -->
            <template v-slot:[`item.transferRate`]="{ item, value }">
              <div v-if="readOnlyCheck(2, item)" class="align-left">
                {{ item.transferRate }}
              </div>
              <div v-else>
                <v-text-field
                  :value="value"
                  outlined
                  dense
                  :rules="[
                    !item.billingItems || isDisabledOffice(item) || rules.required,
                    !item.billingItems || isDisabledOffice(item) || rules.isNumber,
                    !item.billingItems || isDisabledOffice(item) || rules.checkTransferRate,
                    !item.billingItems || isDisabledOffice(item) || rules.isValidTransferRate,
                    !item.billingItems || isDisabledOffice(item) || rules.isHalfWidthNumber,
                  ]"
                  class="input-number"
                  maxLength="3"
                  @load="value = item.transferRate"
                  :disabled="isDisabledOffice(item)"
                  @change="changeTransferRateTxt(item, $event)"
                >
                </v-text-field>
              </div>
            </template>

            <!-- 削除 -->
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                v-if="editing && isAddRow(item.unitPriceSid)"
                small
                @click="deleteRow(item)"
                text
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :bilActualFlg="infoDialog.bilActualFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <!-- エラーメッセージ -->
      <v-dialog v-model="errDialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>警告</v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="errOkClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_BIL_APPROVAL,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    newFlg: true, // 新規登録フラグ
    editing: false, // 編集中フラグ
    isRefreshed: false, // システムデータのリフレッシュが行われたか
    moveFocusFlag: false, // フォーカス移動フラグ
    errorFlg: false, // エラーフラグ
    firstEdit: true,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    alertMessage: "",
    // 取引先(value)
    suppliersSelected: "",
    // 取引先(text)
    supplierNm: "",
    // 請求コード(value)
    billingBussinessNoSelected: "",
    // 請求コード(text)
    billingBussinessNoNm: "",
    // メニュー
    openMenu: null,
    // カレンダーfrom
    billingMonthCal: "",
    billingMonth: "",
    // 検索値保持用
    searchedSuppliers: "",
    searchedBillingBussinessNo: "",
    searchedBillingMonthCal: "",
    baseCd: "",
    loginUserOffice: "",
    sumPriceTxt: "",
    fiveInDiv: "",
    // ヘッダ
    headerItems: [
      // 請求項目
      {
        text: i18n.tc("label.lbl_billingItems"),
        value: "billingItems",
        width: "7%",
        align: "left",
        class: "asta",
      },
      // 収入科目
      {
        text: i18n.tc("label.lbl_billingSubject"),
        value: "billingSubject",
        width: "4.5%",
        align: "left",
        class: "asta",
      },
      // 単価区分
      {
        text: i18n.tc("label.lbl_unitPrice") + "\r\n" + i18n.tc("label.lbl_classification"),
        value: "unitPriceClassificPrice",
        width: "4.5%",
        align: "left",
        class: "asta",
      },
      // 単価内訳
      {
        text: i18n.tc("label.lbl_unitPriceClassContents"),
        value: "unitPriceClassContents",
        width: "12%",
        align: "left",
        class: "asta",
      },
      // 単価Gr
      {
        text: i18n.tc("label.lbl_unitPrice") + "\r\n" + i18n.tc("label.lbl_gr"),
        value: "unitPriceGr",
        width: "5.5%",
        align: "center",
      },
      // 期
      {
        text: i18n.tc("label.lbl_period"),
        value: "period",
        width: "2%",
        align: "left",
      },
      // 単価
      {
        text: i18n.tc("label.lbl_unitPrice"),
        value: "unitPrice",
        width: "8.3%",
        align: "left",
        class: "asta",
      },
      // 単位
      {
        text: i18n.tc("label.lbl_unitPriceUnit"),
        value: "unitPriceCd",
        width: "4.5%",
        align: "center",
        class: "asta",
      },
      // 実績数量
      {
        text: i18n.tc("label.lbl_achieveQuantity"),
        value: "achieveQuantity",
        width: "6%",
        align: "left",
        class: "asta",
      },
      // 金額
      {
        text: i18n.tc("label.lbl_price"),
        value: "price",
        width: "6%",
        align: "left",
      },
      // 単価係数
      {
        text: i18n.tc("label.lbl_convertedValue"),
        value: "convertedValue",
        width: "5%",
        align: "left",
        class: "asta",
      },
      // 売上営業所
      {
        text: i18n.tc("label.lbl_sales") + "\r\n" + i18n.tc("label.lbl_salesOffice"),
        value: "officeSales",
        width: "5%",
        align: "left",
        class: "asta",
      },
      // 倉庫コード
      {
        text: i18n.tc("label.lbl_warehouseCd"),
        value: "warehouseCd",
        width: "7%",
        align: "left",
      },
      // 税区分
      {
        text: i18n.tc("label.lbl_taxClassification"),
        value: "taxClassification",
        width: "6%",
        align: "left",
        class: "asta",
      },
      // 計算区分
      {
        text: i18n.tc("label.lbl_calculationClassification"),
        value: "calculationClassification",
        width: "7.5%",
        align: "left",
      },
      // 振替率%
      {
        text: i18n.tc("label.lbl_transferRate"),
        value: "transferRate",
        width: "4.5%",
        align: "left",
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "4%",
        align: "center",
      },
    ],
    inputList: [],
    deleteList: [],
    supplierList: [],
    billingBussinessNoList: [],
    billingItemList: [], // 請求Cd
    billingSubjectList: [], // 科目Cd
    unitPriceClassificPriceList: [], // 単価Cdリスト
    periodList: [], // 期リスト
    unitPriceGrList: [], // 単価Gr/単価Gr名リスト
    unitPriceCdList: [], // 単位リスト
    officeSalesList: [], // 営業所リスト
    warehouseList: [], // 倉庫コードリスト
    taxClassificationList: [], // 税区分
    calculationClassificationList: [], // 計算区分
    ginesuSouko: [],
    ginesuOffice: [],
    invoiceActDeleteList: [],

    // テーブルアイテム
    whatPeriod: "",
    verSid: "",
    invoiceItemSid: "",
    unitPriceSid: "",
    billingItems: "",
    billingSubject: "",
    unitPriceClassificPrice: "",
    unitPriceClassContents: "",
    unitPriceGr: "",
    periodDiv: "",
    unitPrice: "",
    unitPriceCd: "",
    achieveQuantity: "",
    price: "",
    convertedValue: "",
    officeSales: "",
    warehouseCd: "",
    taxClassification: "",
    calculationClassification: "",
    deleteFlg: 0,
    updateDatetime: "",
    invListNo: "",
    autoNo: "",
    minVal: "",
    maxVal: "",
    consumptionTax: "",
    firstFlg: true,
    addItem: false,

    prevList: "",
    prevLength: 0,
    afterList: "",

    businessVerSid: "",

    returnURL: "",

    rules: {
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
        }
        if (value === "") {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
      yyyymm: (value) =>
        value == null ||
        (!!value &&
          value.length == 7 &&
          new Date(value.split("/")[0] + "-" + value.split("/")[1]) != "Invalid Date") ||
        i18n.tc("check.chk_inputDate"),
      checkPriceValue: function (value) {
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^([1-9]\d{1,6})$|^(\d{1,1})$/;
        if (regex.test(value)) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_7");
        }
        return true;
      },
      checkAchieveQuantity: function (value) {
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^(\d{1,5}|0)(\.[0-9]{1,2})?$/;
        if (regex.test(value)) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_5-2");
        }
        return true;
      },
      checkConvertedValue: function (value) {
        let isValid;
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value.replaceAll(",", "").replaceAll("¥", "");
        }
        const regex = /^([0-1])(\.\d{1,2})$|^[1]$/;
        if (regex.test(value)) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputNumber_1-2");
        }
        return true;
      },
      isNonZero: (value) =>
        0 < Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", "")) ||
        i18n.tc("check.chk_inputThanZero"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      checkContents: (value) => value.length <= 22 || "22文字以内で入力して下さい。",
      isValidUnitPriceClassContents: function (isError) {
        if (!isError) {
          return i18n.tc("check.chk_inputFullWidth");
        }
        return true;
      },
      isValidTransferRate: function (value) {
        let num = Number(value);
        if (num < 1 || num > 100) {
          return i18n.tc("check.chk_inputNumberRange_1-100");
        }
        return true;
      },
      isHalfWidthNumber(value) {
        let isValid = String(value).match(/[０-９]/g) === null;
        if (!isValid) {
          return i18n.tc("check.chk_inputHalfWidth");
        }
        return true;
      },
      checkTransferRate: function (value) {
        let isValid;
        const regex = /^(\d{0,3})$/;
        if (regex.test(Number(value))) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (!isValid) {
          return i18n.tc("check.chk_inputInteger");
        }
        return true;
      },
    },

    ConfirmDialog: {
      message: "",
      redMessage: "",
      okAction: () => {},
      isOpen: false,
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      bilActualFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // エラー用ダイアログ
    errDialog: false,
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // 前画面からのパラメータ設定
    this.newFlg = this.$route.params.newFlg; // 遷移元画面ID
    this.suppliersSelected = this.$route.params.supplier;
    this.supplierNm = this.$route.params.supplierNm;
    this.billingBussinessNoSelected = this.$route.params.billingBussinessNo;
    this.billingBussinessNoNm = this.$route.params.billingBussinessNoNm;
    this.billingMonth = this.$route.params.billingMonth;
  },

  methods: {
    clickForcus(id, index) {
      let elem = document.getElementById(id + index);
      setTimeout(function () {
        elem.blur();
      }, 1);
      setTimeout(function () {
        elem.setAttribute("tabindex", "0");
        elem.focus();
      }, 10);
    },
    // 初期化
    init() {
      this.inputList = [];
      this.deleteList = [];
      this.isRefreshed = false;
      let listBox = this.getListBox();

      Promise.all([listBox]).then(() => {
        this.setBaseCd();
        if (this.newFlg) {
          // 新規登録遷移の場合
          this.getInvoiceActNew();
          // 編集可
          this.editing = true;
        } else {
          // 詳細遷移の場合
          this.getInvoiceActDetail();
          // 編集不可
          this.editing = false;
        }
      });
    },

    // リストボックス取得処理
    getListBox() {
      // 取引先
      const cust = this.getCustomInfo(appConfig.ISGETCLIENT.CLIENT);
      // ログインユーザ営業所
      const office = this.getCustomInfo(appConfig.ISGETCLIENT.FROMTO);
      // 請求Cd
      const inv = this.getInvoiceItemList();
      // 単価Cd
      const unitPriceCd = this.getUnitPriceCdListBox();
      // 単価Gr
      const unitGr = this.getUnitPriceGrListBox();
      // 期
      const period = this.getPeriodList();
      // 単位Cd
      const unitCd = this.getUnitCdListBox();
      // 税区分
      const tax = this.getTaxClassificationList();
      // 計算区分
      const caluc = this.getCalculationClassificationList();
      // 業務マスタ
      const business = this.getBusinessMst();

      return Promise.all([
        cust,
        office,
        inv,
        unitPriceCd,
        unitGr,
        period,
        unitCd,
        tax,
        caluc,
        business,
      ]);
    },

    // 取引先リストボックス取得
    getCustomInfo(isClient) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      let clientList;
      if (isClient === appConfig.ISGETCLIENT.CLIENT) {
        clientList = getParameter.getRelatedBiz(isClient, dateTimeHelper.convertJST());
      } else {
        clientList = getParameter.getRelatedBiz(
          isClient,
          dateTimeHelper.convertJST(),
          false,
          sessionStorage.getItem("comp_sid")
        );
      }
      return Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          if (isClient === appConfig.ISGETCLIENT.CLIENT) {
            this.supplierList = result[0];
          } else {
            this.loginUserOffice = result[0];
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // G請求項目マスタ取得
    getInvoiceItemList(billingItem, index) {
      if (billingItem == "") {
        this.billingSubjectList = [{}];
        return;
      }
      // G請求コード
      const invoiceItemList = getParameter.getInvoiceItemList(
        "0",
        this.suppliersSelected,
        this.billingBussinessNoSelected,
        billingItem
      );

      let kamoku = [];
      let office = [];
      let souko = [];

      return Promise.all([invoiceItemList])
        .then((result) => {
          // 画面の初期値を設定します。
          result[0].forEach((arg) => {
            if (billingItem !== void 0) {
              let kamokuCd = this.isEmpty(arg.text) ? "" : String(arg.text).trim();
              if (kamokuCd) {
                let name = arg.name !== undefined ? arg.name : "";
                kamoku.push({
                  text: arg.text,
                  code: arg.text,
                  name: name,
                  value: arg.value,
                });
              }
            }

            let officeSid = this.isEmpty(arg.officeSid) ? "" : String(arg.officeSid).trim();
            if (officeSid) {
              office.push({
                text: arg.officeCd,
                name: arg.officeName,
                value: arg.officeSid,
              });
            }

            let warehouse = this.isEmpty(arg.warehouseSid) ? "" : String(arg.warehouseSid).trim();
            if (warehouse) {
              souko.push({
                text: arg.warehouseCd,
                name: arg.warehouseName,
                value: arg.warehouseSid,
              });
            }
          });

          if (billingItem !== void 0) {
            if (souko.length < 1) {
              souko.unshift({ text: "", name: "", value: "", sid: "" });
            }

            if (office.length < 1) {
              office = this.officeSalesList;
              office.unshift({ text: "", name: "", value: "", sid: "" });
            }

            this.inputList[index].billingSubjectList = kamoku;
            this.inputList[index].billingSubject = this.getInvoiceSid(billingItem);
            this.inputList[index].officeSalesList = office;
            let inputOffice = this.inputList[index].officeSales;
            this.inputList[index].officeSales = this.inputList[index].firstFlg
              ? inputOffice
              : office[0].value;
            this.inputList[index].warehouseList = souko;
            let inputSouko = this.inputList[index].warehouseCd;
            this.inputList[index].warehouseCd = this.inputList[index].firstFlg
              ? inputSouko
              : souko[0].value;
            this.inputList[index].firstFlg = false;
            if (
              !this.isDisabledOffice(this.inputList[index]) &&
              this.isEmpty(this.inputList[index].transferRate)
            ) {
              this.inputList[index].transferRate = 90;
            }
          } else {
            // 重複を削除したリスト
            souko = souko.filter(function (element, index, self) {
              return self.findIndex((e) => e.value === element.value) === index;
            });

            office = office.filter(function (element, index, self) {
              return self.findIndex((e) => e.value === element.value) === index;
            });
            this.billingItemList = result[0];
            this.officeSalesList = office;
            this.warehouseList = souko;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        });
    },

    // リストボックス取得
    getSubjectList(item, index) {
      if (item.billingItems != "") {
        this.getInvoiceItemList(item.billingItems, index);
        return this.billingItemList;
      } else {
        this.inputList[index].billingSubjectList = [];
        this.inputList[index].billingSubject = "";
        this.inputList[index].officeSales = "";
        this.inputList[index].warehouseCd = "";
        this.inputList[index].officeSalesList = [];
        this.inputList[index].warehouseList = [];
        this.inputList[index].transferRate = null;
        return [];
      }
    },

    // 単位CDリストボックス取得
    getUnitPriceCdListBox() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 単位リスト
      const list = getParameter.getUnitPriceCdList("0");

      return Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceClassificPriceList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 単位Grリストボックス取得
    getUnitPriceGrListBox() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 単位リスト
      const list = getParameter.getUnitPriceGrList("0");

      return Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceGrList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 期区分
    getPeriodList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 業務No
      config.params.businessNo = this.billingBussinessNoSelected;
      // マスタ取得区分
      config.params.isMst = "0";

      // console.debug("getPeriodList() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_BUSINESS, config)
          .then((response) => {
            // console.debug("getPeriodList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", value: "" }];
              jsonData.resIdv.businesses.forEach((row) => {
                if (row.whatPeriod == "01") {
                  this.periodSelected = "";
                  list.push({
                    text: "1",
                    value: "01",
                  });
                } else if (row.whatPeriod == "02") {
                  this.periodSelected = "";
                  list.push(
                    {
                      text: "1",
                      value: "01",
                    },
                    {
                      text: "2",
                      value: "02",
                    }
                  );
                } else {
                  this.periodSelected = "";
                  list.push(
                    {
                      text: "1",
                      value: "01",
                    },
                    {
                      text: "2",
                      value: "02",
                    },
                    {
                      text: "3",
                      value: "03",
                    }
                  );
                }
                this.businessVerSid = row.verSid;
              });
              this.periodList = list;
              this.periodList.unshift({ name: "", text: "", value: "" });
              this.periodList.sort();
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 単位リストボックス取得
    getUnitCdListBox() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 単位リスト
      const list = getParameter.getUnitCdList("0");

      return Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitPriceCdList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 営業所リストボックス
    getOfficeIdList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // ★必須パラメータ
      config.params.isMst = "1";
      config.params.relatedPartyDiv = "02";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_RELATED, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getOfficeIdList() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                if (row.relatedPartyLanguage.length > 0) {
                  list.push({
                    text: row.relatedPartyLanguage[0].relatedPartyName ?? "",
                    value: row.officeSid ?? "",
                    name: row.officeCd ?? "",
                  });
                }
              });
              this.officeSalesList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 倉庫マスタ取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "", name: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                if (row.warehouseLanguage[0] != null && row.warehouseLanguage[0].length > 0) {
                  list.push({
                    text: row.warehouseLanguage[0].warehouseName ?? "",
                    value: row.warehouseLanguage[0].warehouseSid ?? "",
                    name: row.warehouseCd ?? "",
                  });
                }
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 税区分
    getTaxClassificationList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const list = getParameter.getCodeMst(appConfig.CODETYPE.BIL_TAX_DIV);

      return Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.taxClassificationList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 計算区分
    getCalculationClassificationList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const list = getParameter.getCodeMst(appConfig.CODETYPE.BIL_CALCULATE_DIV);

      return Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.calculationClassificationList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 初期表示検索
     * 新規登録遷移の場合、請求実績新規登録集計APIを呼出
     */
    getInvoiceActNew() {
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 請求No
      config.params.businessNo = this.billingBussinessNoSelected;
      // 請求月度
      config.params.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/");

      // console.debug("getInvoiceActNew() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_BIL_ACTUAL_NEW_DETAIL, config)
          .then((response) => {
            // console.debug("getInvoiceActNew() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              const delList = [];
              jsonData.resIdv.newInvoiceActInfo.forEach((row) => {
                if (list.length == 0) {
                  this.verSid = row.verSid; // バージョンSID
                  this.invoiceItemSid = row.invoiceItemSid; // 請求項目ID
                }
                let actQuantity =
                  row.unitCd === "04" && row.calculateDiv === "03"
                    ? Number(row.actQuantity).toFixed(3)
                    : Number(row.actQuantity);
                let actQuantityWithComma = this.parseNumberFormat(actQuantity, "");
                list.push({
                  verSid: row.verSid, // バージョンSID
                  invoiceItemSid: row.invoiceItemSid, // 請求項目ID
                  unitPriceSid: row.unitPriceSid, // 単価SID
                  billingItems: row.gkcode, // 請求項目CD
                  billingSubject: row.gkkamo, // 計上科目CD
                  unitPriceClassificPrice: row.unitPriceCd, // 単価コード
                  unitPriceClassContents: row.unitPriceDetail, // 単価内容
                  unitPriceGr: row.unitPriceGroup, // 単価Gr
                  periodDiv: row.periodDiv, // 期区分
                  unitPrice: this.parseNumberFormat(row.unitPrice, "¥"), // 単価
                  unitPriceCd: row.unitCd, // 単位コード
                  achieveQuantity: actQuantityWithComma, // 実績数量
                  price: this.calcPrice(
                    row.actQuantity,
                    row.unitPrice,
                    row.unitPriceFacto,
                    this.fiveInDiv
                  ),
                  convertedValue: row.unitPriceFactor, // 単価係数
                  officeSales: this.isEmpty(row.appOfficeSid) ? "" : row.appOfficeSid.trim(), // 営業所SID
                  warehouseCd: this.isEmpty(row.appWarehouseSid) ? "" : row.appWarehouseSid.trim(), // 倉庫SID
                  taxClassification: row.taxDiv, // 税区分
                  calculationClassification: row.calculateDiv, // 計算区分
                  deleteFlg: 0, // 削除フラグ
                  updateDatetime: row.updateDatetime, // 更新日時
                  billingSubjectList: [],
                  officeSalesList: [],
                  warehouseList: [],
                  firstFlg: true,
                  addItem: false, // 実績数量以外編集不可
                  transferRate: row.giroPercent == null ? "" : row.giroPercent,
                  fiveInDiv: this.fiveInDiv,
                });
              });
              jsonData.resIdv.invoiceActDeleteList.forEach((row) => {
                delList.push({
                  verSid: row.verSid, // バージョンSID
                  yyyyMm: row.yyyyMm, // 月度
                  invoiceItemSid: row.invoiceItemSid, // 請求項目SID
                  periodDiv: row.periodDiv, // 期区分
                  unitPriceSid: row.unitPriceSid, // 単価SID
                  autoNo: row.autoNo, // スポット用の連番
                  updateDatetime: row.updateDatetime, // 請求実績更新日付
                });
              });

              this.inputList = list;
              this.invoiceActDeleteList = delList;
              resolve(response);

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              this.editing = false;
              this.errorFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            this.editing = false;
            this.errorFlg = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 初期表示検索
     * 詳細遷移の場合、請求実績詳細取得APIを呼出
     */
    getInvoiceActDetail() {
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 請求No
      config.params.businessNo = this.billingBussinessNoSelected;
      // 請求月度
      config.params.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/");

      // console.debug("getInvoiceActDetail() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // TODO API名
          .secureGet(appConfig.API_URL.BIZ_BIL_ACTUAL_DETAIL, config)
          .then((response) => {
            // console.debug("getInvoiceActDetail() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // let souko = [];
              // let office = [];
              const list = [];
              jsonData.resIdv.invoiceActDetail.forEach((row) => {
                if (list.length == 0) {
                  this.verSid = row.verSid; // バージョンSID
                  this.invListNo = row.invListNo; // 請求伝票No
                }
                let actQuantity =
                  row.unitCd === "04" && row.calculateDiv === "03"
                    ? Number(row.actQuantity).toFixed(3)
                    : Number(row.actQuantity);
                let actQuantityWithComma = this.parseNumberFormat(actQuantity, "");
                list.push({
                  verSid: row.verSid, // バージョンSID
                  invoiceItemSid: row.invoiceItemSid, // 請求項目ID
                  unitPriceSid: row.unitPriceSid, // 単価SID
                  billingItems: row.gkcode, // 請求項目CD
                  billingSubject: row.gkkamo, // 計上科目CD
                  unitPriceClassificPrice: row.unitPriceCd, // 単価コード
                  unitPriceClassContents: row.unitPriceDetail, // 単価内容
                  unitPriceGr: row.unitPriceGroup, // 単価Gr
                  periodDiv: row.periodDiv, // 期区分
                  unitPrice: this.parseNumberFormat(row.unitPrice, "¥"), // 単価
                  unitPriceCd: row.unitCd, // 単位コード
                  achieveQuantity: actQuantityWithComma, // 実績数量
                  price: this.calcPrice(
                    row.actQuantity,
                    row.unitPrice,
                    row.unitPriceFactor,
                    this.fiveInDiv
                  ), // 金額
                  convertedValue: row.unitPriceFactor, // 単価係数
                  officeSales: this.isEmpty(row.appOfficeSid) ? "" : row.appOfficeSid.trim(), // 営業所SID
                  warehouseCd: this.isEmpty(row.appWarehouseSid) ? "" : row.appWarehouseSid.trim(), // 倉庫SID
                  taxClassification: row.taxDiv, // 税区分
                  calculationClassification: row.calculateDiv, // 計算区分
                  deleteFlg: 0, // 削除フラグ
                  updateDatetime: row.updateDatetime, // 更新日時
                  invListNo: row.invListNo, // 請求伝票No
                  autoNo: row.autoNo, // 連番
                  billingSubjectList: [],
                  officeSalesList: [],
                  warehouseList: [],
                  firstFlg: true,
                  addItem: false, // 実績数量以外編集不可
                  transferRate: row.giroPercent == null ? "" : row.giroPercent,
                  fiveInDiv: this.fiveInDiv,
                });
              });

              this.inputList = list;
              this.prevList = JSON.parse(JSON.stringify(list));
              this.prevLength = this.prevList.length;

              resolve(response);

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              this.editing = false;
              this.errorFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            this.editing = false;
            this.errorFlg = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 完了ボタン押下時
     * 請求実績登録・更新・削除APIを呼出
     */
    registInvoiceActualApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_005; // 画面ID

      var bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        var inputMap = {};
        // 業務No
        inputMap.businessNo = this.billingBussinessNoSelected;
        // バージョンSID
        inputMap.verSid = this.inputList[i].verSid;
        // 請求項目SID
        inputMap.invoiceItemSid = this.isAddRow(this.inputList[i].unitPriceSid)
          ? this.inputList[i].billingSubject
          : this.inputList[i].invoiceItemSid;
        // 取引先SID
        inputMap.clientSid = this.suppliersSelected;
        // 営業所SID
        inputMap.officeSid = sessionStorage.getItem("sales_office_sid");
        // 単価SID
        inputMap.unitPriceSid = this.inputList[i].unitPriceSid;
        // 月度
        inputMap.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/");
        // 期区分
        inputMap.periodDiv = this.inputList[i].periodDiv;
        // 単価コード
        inputMap.unitPriceCd = this.inputList[i].unitPriceClassificPrice;
        // 単価内訳
        inputMap.unitPriceDetail = this.inputList[i].unitPriceClassContents;
        // 単価Gr
        inputMap.unitPriceGroup = this.inputList[i].unitPriceGr;
        // 単価
        inputMap.unitPrice = this.replaceNum(this.inputList[i].unitPrice);
        // 単位コード
        inputMap.unitCd = this.inputList[i].unitPriceCd;
        // 実績数量
        inputMap.actQuantity = this.replaceNum(this.inputList[i].achieveQuantity);
        // 金額
        inputMap.amountMoney = this.replaceNum(this.inputList[i].price);
        // 単価係数
        inputMap.unitPriceFactor = this.inputList[i].convertedValue;
        // 営業所SID
        inputMap.appOfficeSid = this.isEmpty(this.inputList[i].officeSales)
          ? ""
          : this.inputList[i].officeSales.trim();
        // 倉庫SID
        inputMap.appWarehouseSid = this.isEmpty(this.inputList[i].warehouseCd)
          ? ""
          : this.inputList[i].warehouseCd.trim();
        // 税区分
        inputMap.taxDiv = this.inputList[i].taxClassification;
        // 計算区分
        inputMap.calculateDiv = this.inputList[i].calculationClassification;
        let transferRateInInputList = this.inputList[i].transferRate;
        // 振替率％
        inputMap.giroPercent = this.isEmpty(transferRateInInputList)
          ? null
          : Number(transferRateInInputList);
        // 削除フラグ
        inputMap.deleteFlg = this.inputList[i].deleteFlg;
        // 請求伝票No
        inputMap.invListNo = this.newFlg ? "" : this.inputList[i].invListNo;
        // 自動連番
        inputMap.autoNo =
          this.newFlg || this.inputList[i].addItem ? null : this.inputList[i].autoNo;
        // 更新日時
        inputMap.updateDatetime = this.inputList[i].updateDatetime;
        bodyList.push(inputMap);
      }

      for (var j = 0; j < this.deleteList.length; j++) {
        var deleteMap = {};
        // 業務No
        deleteMap.businessNo = this.billingBussinessNoSelected;
        // バージョンSID
        deleteMap.verSid = this.deleteList[j].verSid;
        // 請求項目SID
        deleteMap.invoiceItemSid = this.deleteList[j].invoiceItemSid;
        // 取引先SID
        deleteMap.clientSid = this.suppliersSelected;
        // 営業所SID
        deleteMap.officeSid = sessionStorage.getItem("sales_office_sid");
        // 単価SID
        deleteMap.unitPriceSid = this.deleteList[j].unitPriceSid;
        // 月度
        deleteMap.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/");
        // 期区分
        deleteMap.periodDiv = this.deleteList[j].periodDiv;
        // 単価コード
        deleteMap.unitPriceCd = this.deleteList[j].unitPriceClassificPrice;
        // 単価内訳
        deleteMap.unitPriceDetail = this.deleteList[j].unitPriceClassContents;
        // 単価Gr
        deleteMap.unitPriceGroup = this.deleteList[j].unitPriceGroup;
        // 単価
        deleteMap.unitPrice = this.replaceNum(this.deleteList[j].unitPrice);
        // 単位コード
        deleteMap.unitCd = this.deleteList[j].unitPriceCd;
        // 実績数量
        deleteMap.actQuantity = this.replaceNum(this.deleteList[j].achieveQuantity);
        // 金額
        deleteMap.amountMoney = this.replaceNum(this.deleteList[j].price);
        // 単価係数
        deleteMap.unitPriceFactor = this.deleteList[j].convertedValue;
        // 営業所SID
        deleteMap.appOfficeSid = this.isEmpty(this.deleteList[j].officeSales)
          ? ""
          : this.deleteList[j].officeSales.trim();
        // 倉庫SID
        deleteMap.appWarehouseSid = this.isEmpty(this.deleteList[j].warehouseCd)
          ? ""
          : this.deleteList[j].warehouseCd.trim();
        // 税区分
        deleteMap.taxDiv = this.deleteList[j].taxClassification;
        // 計算区分
        deleteMap.calculateDiv = this.deleteList[j].calculationClassification;
        let transferRateInDeleteList = this.deleteList[j].transferRate;
        // 振替率％
        deleteMap.giroPercent = this.isEmpty(transferRateInDeleteList)
          ? null
          : Number(transferRateInDeleteList);
        // 削除フラグ
        deleteMap.deleteFlg = this.deleteList[j].deleteFlg;
        // 請求伝票No
        deleteMap.invListNo = this.newFlg ? "" : this.deleteList[j].invListNo;
        // 自動連番
        deleteMap.autoNo =
          this.newFlg || this.deleteList[j].addItem ? null : this.deleteList[j].autoNo;
        // 更新日時
        deleteMap.updateDatetime = this.deleteList[j].updateDatetime;
        bodyList.push(deleteMap);
      }

      body.reqIdv.invoiceAct = bodyList;
      body.reqIdv.invoiceActDeleteList = this.invoiceActDeleteList;
      // console.debug("registInvoiceActualApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_BIL_ACTUAL_REGIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("registInvoiceActualApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_complete"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              resolve(response);
              this.editing = false;
              // リストクリア
              this.inputList = [];
              this.deleteList = [];
              this.isRefreshed = false;
              // 再検索
              this.getInvoiceActDetail();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * ギネス連携登録API呼出処理
     * ギネス連携用のテーブルに登録する
     */
    sendApi() {
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_005; // 画面ID
      body.reqIdv.clientSid = this.suppliersSelected; // 取引先SID
      body.reqIdv.businessNo = this.billingBussinessNoSelected; // 請求No
      body.reqIdv.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/"); // 請求月度

      // console.debug("sendApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_BIL_SEND_GINES, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("sendApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                i18n.tc("btn.btn_send"),
              ]);
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = true;
              resolve(response);
              // リストクリア
              this.inputList = [];
              this.deleteList = [];
              // 再検索
              this.getInvoiceActDetail();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 請求明細書PDF発行API呼出処理
     */
    getInvoiceDetailsReport() {
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_005; // 画面ID
      body.reqIdv.invoiceMonthly = this.formatDateDelimiter(this.billingMonth, "/"); // 請求月度
      //body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid"); //営業所SID
      body.reqIdv.officeSid = this.officeSid; //営業所SID
      body.reqIdv.clientSid = this.suppliersSelected; // 取引先SID
      body.reqIdv.businessNo = this.billingBussinessNoSelected; // 請求No
      body.reqIdv.invoiceListNo = this.invListNo; // 請求伝票No

      // console.debug("getInvoiceDetailsReport() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_BIL_INVOICE_DETAILS_REPORT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("getInvoiceDetailsReport() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              //this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
              //i18n.tc("btn.btn_send"),
              //]);
              //this.infoDialog.title = "結果";
              //this.infoDialog.isOpen = true;
              //this.infoDialog.screenFlag = true;
              //this.infoDialog.bilActualFlg = true;

              console.log("success");
              this.returnURL = jsonData.resIdv.filePath;
              //location.href = this.returnURL;
              this.link();
              resolve(response);

              // リストクリア
              //this.inputList = [];
              //this.deleteList = [];
              // 再検索
              //this.getInvoiceActDetail();
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 編集
    edit() {
      this.editing = true;

      this.$nextTick(() => {
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.isAddRow(this.inputList[i].unitPriceSid)) {
            this.getInvoiceItemList(this.inputList[i].billingItems, i);
            this.inputList[i].billingSubject = this.inputList[i].invoiceItemSid;
          }
        }
      });

      return;
    },

    // 請求項目プルダウン取得処理
    checkBillingItems(value) {
      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value.billingItems) {
          return this.billingItemList[i].name;
        }
      }
    },

    // 請求項目名称取得処理
    getBillingItemsText(value) {
      if (this.isEmpty(value.billingItems)) {
        return "";
      }

      let name = value.billingItems + "\r\n";
      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value.billingItems) {
          return name + this.billingItemList[i].name;
        }
      }
      return name;
    },

    // 請求項目ID取得処理
    getInvoiceSid(value) {
      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value) {
          return this.billingItemList[i].sid;
        }
      }
    },

    // 税率取得処理
    getConsumptionTax(value) {
      if (this.isEmpty(value.billingItems)) {
        return "";
      }

      for (var i = 0; i < this.billingItemList.length; i++) {
        if (this.billingItemList[i].value == value.billingItems) {
          return this.billingItemList[i].gkritu;
        }
      }

      return "";
    },

    // 科目項目プルダウン取得処理
    checkBillingSubject(value) {
      for (var i = 0; i < this.billingSubjectList.length; i++) {
        if (this.billingSubjectList[i].value == value.billingSubject) {
          return this.billingSubjectList[i].name;
        }
      }
    },

    // 科目項目名称取得処理
    getBillingSubjectText(value) {
      if (this.isEmpty(value.billingSubject)) {
        return "";
      }

      let name = value.billingSubject + "\r\n";
      for (var i = 0; i < this.billingSubjectList.length; i++) {
        if (this.billingSubjectList[i].value == value.billingSubject) {
          return name + this.billingSubjectList[i].name;
        }
      }
      return name;
    },

    // 単価区分プルダウン取得処理
    checkUnitPriceClassificPrice(value) {
      for (var i = 0; i < this.unitPriceClassificPriceList.length; i++) {
        if (this.unitPriceClassificPriceList[i].value == value.unitPriceClassificPrice) {
          return this.unitPriceClassificPriceList[i].name;
        }
      }
    },

    // 単価区分項目名称取得処理
    getUnitPriceClassificPrice(value) {
      if (this.isEmpty(value.unitPriceClassificPrice)) {
        return "";
      }

      let name = value.unitPriceClassificPrice + "\r\n";
      for (var i = 0; i < this.unitPriceClassificPriceList.length; i++) {
        if (this.unitPriceClassificPriceList[i].value == value.unitPriceClassificPrice) {
          return name + this.unitPriceClassificPriceList[i].name;
        }
      }

      return name;
    },

    // 単価Grプルダウン取得処理
    checkUnitPriceGrList(value) {
      for (var i = 0; i < this.unitPriceGrList.length; i++) {
        if (this.unitPriceGrList[i].value == value.unitPriceGr) {
          return this.unitPriceGrList[i].name;
        }
      }
    },

    // 単価Gr名称取得処理
    getUnitPriceGrList(value) {
      if (this.isEmpty(value.unitPriceGr)) {
        return "";
      }

      let name = value.unitPriceGr + "\r\n";
      for (var i = 0; i < this.unitPriceGrList.length; i++) {
        if (this.unitPriceGrList[i].value == value.unitPriceGr) {
          return name + this.unitPriceGrList[i].name;
        }
      }

      return name;
    },

    // 期名称取得
    getPeriod(value) {
      if (
        this.isEmpty(value.periodDiv) ||
        isNaN(Number(value.periodDiv)) ||
        Number(value.periodDiv) == "00"
      ) {
        return "";
      }
      return Number(value.periodDiv);
    },

    // 単位コード/単位名プルダウン取得処理
    checkUnitPriceCd(value) {
      for (var i = 0; i < this.unitPriceCdList.length; i++) {
        if (this.unitPriceCdList[i].value == value.unitPriceCd) {
          return this.unitPriceCdList[i].name;
        }
      }
    },

    // 単位コード/単位名名称取得処理
    getUnitPriceCd(value) {
      if (this.isEmpty(value.unitPriceCd)) {
        return "";
      }
      let name = value.unitPriceCd + "\r\n";
      for (var i = 0; i < this.unitPriceCdList.length; i++) {
        if (this.unitPriceCdList[i].value == value.unitPriceCd) {
          return name + this.unitPriceCdList[i].name;
        }
      }
      return name;
    },

    // 売上営業所リストボックス取得処理
    checkOfficeSales(value) {
      for (var i = 0; i < this.officeSalesList.length; i++) {
        if (this.officeSalesList[i].value == value.officeSales) {
          return this.officeSalesList[i].text;
        }
      }
    },

    // 売上営業所名称取得処理
    getOfficeSales(value) {
      if (this.isEmpty(value.officeSales)) {
        return "";
      }
      //let name = value.officeSales + "\r\n";
      for (var i = 0; i < this.officeSalesList.length; i++) {
        if (this.officeSalesList[i].value == value.officeSales) {
          return this.officeSalesList[i].name;
        }
      }
      //return name;
    },

    // 倉庫コードリストボックス取得処理
    checkWarehouseCd(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouseCd) {
          return this.warehouseList[i].text;
        }
      }
    },

    // 税区分リストボックス取得処理
    checkTaxClassification(value) {
      for (var i = 0; i < this.taxClassificationList.length; i++) {
        if (this.taxClassificationList[i].value == value.taxClassification) {
          return this.taxClassificationList[i].name;
        }
      }
    },

    // 税区分名称取得処理
    getTaxClassification(value) {
      if (this.isEmpty(value.taxClassification)) {
        return "";
      }
      for (var i = 0; i < this.taxClassificationList.length; i++) {
        if (this.taxClassificationList[i].value == value.taxClassification) {
          return this.taxClassificationList[i].name;
        }
      }
      return "";
    },

    // 計算区分リストボックス取得処理
    checkCalculationClassification(value) {
      for (var i = 0; i < this.calculationClassificationList.length; i++) {
        if (this.calculationClassificationList[i].value == value.calculationClassification) {
          return this.calculationClassificationList[i].name;
        }
      }
    },

    // 計算区分名称取得処理
    getCalculationClassification(value) {
      if (this.isEmpty(value.calculationClassification)) {
        return "";
      }
      //let name = value.calculationClassification + "\r\n";
      for (var i = 0; i < this.calculationClassificationList.length; i++) {
        if (this.calculationClassificationList[i].value == value.calculationClassification) {
          return this.calculationClassificationList[i].name;
        }
      }
      return "";
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 項目が非活性か
    readOnlyCheck(label, value) {
      if (!this.editing) {
        return true;
      }

      // 変更可能な計算区分か
      let changeCalcFlg =
        value.calculationClassification == "02" || this.isCalcSpot(value.calculationClassification);
      // 追加されたアイテムか
      // label:1は編集可能項目、label:2は行追加時にのみ編集可能な項目
      let addFlg = value.addItem;
      if (label == 1 && changeCalcFlg) {
        return false;
      } else if (label == 2 && addFlg == true && changeCalcFlg) {
        return false;
      } else if (label == 2 && this.isAddRow(value.unitPriceSid)) {
        return false;
      }
      return true;
    },

    // 行追加
    addRow() {
      this.inputList.push({
        verSid: this.isEmpty(this.verSid) ? this.businessVerSid : this.verSid,
        invoiceItemSid: "",
        unitPriceSid: "9999999999",
        billingItems: "",
        billingSubject: "",
        unitPriceClassificPrice: "",
        unitPriceClassContents: "",
        unitPriceGr: "",
        unitPrice: "",
        unitPriceCd: "",
        periodDiv: "",
        achieveQuantity: "",
        price: "",
        convertedValue: "",
        officeSales: "",
        warehouseCd: "",
        taxClassification: "",
        calculationClassification: "99",
        deleteFlg: 0,
        invListNo: "",
        updateDatetime: "",
        billingSubjectList: [],
        officeSalesList: [],
        warehouseList: [],
        firstFlg: false,
        addItem: true,
        transferRate: "",
      });
      this.scrollToEnd();
      // this.focusIdx = this.inputList[this.inputList.length - 1].index;
      // // フォーカス移動フラグをONに
      // this.moveFocusFlag = true;
    },

    /**
     * チェックエラーがある行までスクロールを移動する
     */
    scrollToErrorRow() {
      let table = this.$refs.listData.$el.childNodes[0];
      // バリデーションのstyleが適用されるまで待機した後に処理実行
      setTimeout(function () {
        let position = document.getElementsByClassName("error--text")[0];
        let headerHeight = table.childNodes[0].rows[0].clientHeight;
        let rowHeight = table.childNodes[0].rows[1].clientHeight;
        let ratio = Math.floor((headerHeight / rowHeight) * Math.pow(10, 1)) / Math.pow(10, 1);
        rowHeight = ratio == 0.8 ? rowHeight : headerHeight / 0.8;
        table.scrollTo(0, position.offsetTop - (table.offsetTop + rowHeight));
      }, 100);
    },

    /**
     * 最終行にスクロールを移動する
     */
    scrollToEnd() {
      let table = this.$refs.listData.$el.childNodes[0];
      // 行追加後にスクロールバーの高さが変更されるまで待機した後に処理実行
      setTimeout(function () {
        table.scrollTo(0, table.scrollHeight);
      }, 100);
    },

    // 確認ダイアログ
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action;
      switch (button) {
        // 完了
        case "btn_complete":
          if (this.inputList.length == 0 && this.deleteList.length == 0) {
            this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_006_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            return;
          }
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-RCV-001_003_C");
          action = this.registration();
          break;
        // 戻る
        case "btn_back":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.backTo();
          break;
        // 送信
        case "btn_send":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_send")]);
          action = this.send();
          break;
        default:
          isValidationRequired = true;
          message = "";
      }

      if (button === "btn_back" && !this.editing) {
        // 請求実績一覧画面へ遷移
        var back = this.backTo();
        back();
      }

      let isValid = !isValidationRequired ? true : this.$refs.form.validate();
      if (!isValid) {
        this.scrollToErrorRow();
        return;
      } else {
        if (button === "btn_complete" && !this.newFlg && this.inputList.length == this.prevLength) {
          if (!this.checkChange()) {
            this.infoDialog.message = messsageUtil.getMessage("P-BIL-005_001_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            this.infoDialog.firstPageFlag = true;
            return;
          }
        }
        this.ConfirmDialog.message = message;
        this.ConfirmDialog.okAction = action;
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
      }
    },

    // 変更チェック
    checkChange() {
      if (this.inputList.length != this.prevList.length) {
        return false;
      }

      for (var i = 0; i < this.inputList.length; i++) {
        if (
          this.inputList[i].invoiceItemSid != this.prevList[i].invoiceItemSid ||
          this.inputList[i].unitPriceSid != this.prevList[i].unitPriceSid ||
          this.inputList[i].billingItems != this.prevList[i].billingItems ||
          this.inputList[i].unitPriceClassificPrice != this.prevList[i].unitPriceClassificPrice ||
          this.inputList[i].unitPriceClassContents != this.prevList[i].unitPriceClassContents ||
          this.inputList[i].unitPriceGr != this.prevList[i].unitPriceGr ||
          this.inputList[i].unitPrice != this.prevList[i].unitPrice ||
          this.inputList[i].unitPriceCd != this.prevList[i].unitPriceCd ||
          this.inputList[i].achieveQuantity != this.prevList[i].achieveQuantity ||
          this.inputList[i].price != this.prevList[i].price ||
          String(this.inputList[i].convertedValue) != String(this.prevList[i].convertedValue) ||
          this.inputList[i].officeSales.trim() != this.prevList[i].officeSales.trim() ||
          this.inputList[i].warehouseCd.trim() != this.prevList[i].warehouseCd.trim() ||
          this.inputList[i].taxClassification != this.prevList[i].taxClassification ||
          this.inputList[i].calculationClassification !=
            this.prevList[i].calculationClassification ||
          this.inputList[i].transferRate != this.prevList[i].transferRate
        ) {
          return true;
        }
      }
      return false;
    },

    // 完了ボタン押下
    registration() {
      return () => {
        this.registInvoiceActualApi();
      };
    },

    // 送信ボタン押下
    send() {
      return () => {
        this.sendApi();
      };
    },

    // イベント終了ダイアログ表示
    endEvent(button) {
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [i18n.tc(button)]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.bilActualFlg = true;
    },

    // 戻るボタン押下
    backTo() {
      return () => {
        // 請求実績一覧画面へ遷移
        this.$router.push({
          name: appConfig.SCREEN_ID.P_BIL_002,
          params: {
            searchedSuppliers: this.$route.params.searchedSuppliers,
            searchedBillingBussinessNo: this.$route.params.searchedBillingBussinessNo,
            searchedBillingMonth: this.$route.params.searchedBillingMonth,
            back: true,
          },
        });
      };
    },

    // 削除
    deleteRow(item) {
      this.editedIndex = this.inputList.indexOf(item);
      // 既に登録済みのもの
      if (!this.newFlg && !item.addItem) {
        // var deleteIndex = this.deleteList.indexOf(item);
        // 削除したリストを退避する。
        this.inputList[this.editedIndex].deleteFlg = "1";
        this.deleteList.push(this.inputList[this.editedIndex]);
        // 画面上からは明細削除
        this.inputList.splice(this.editedIndex, 1);
      } else {
        this.inputList.splice(this.editedIndex, 1);
      }
    },

    // PDF出力
    outputPdf() {
      this.getInvoiceDetailsReport();
    },

    // カーソル位置調整
    setCursor() {
      let listLength = this.inputList.length;
      let cursolPosition = String(listLength - 1);
      eval("this.$refs.cursorProcd" + cursolPosition + ".focus()");
    },

    // アラートダイアログクローズ
    errOkClicked() {
      this.errDialog = false;
    },

    // カレンダコンポーネントの日付はyyyy-MMのためyyyy/MMに変換する。
    formatDate(date) {
      if (!date) return null;
      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },

    // 日付形式を変換する。
    formatDateDelimiter(date, delimiter, changeDelimiter1 = "", changeDelimiter2 = "") {
      if (!date) return null;
      // 月の正規表現（yyyyMM）
      const regex = /^[0-9]{4}(0[1-9]|[1-9]|1[0-2])$/;
      const regex2 = new RegExp("([0-9]{4})" + delimiter + "(0[1-9]|[1-9]|1[0-2])");
      date = commonUtil.zen2han(date);
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date.slice(0, 4) + changeDelimiter1 + date.slice(4) + changeDelimiter2;
      } else if (regex2.test(date)) {
        return date.replaceAll(delimiter, changeDelimiter1);
      } else {
        return date;
      }
    },

    /**
     * カンマ付きの数字に変換する
     * @param {*} value 変換対象の値
     */
    parseNumberFormat(value, delimiter) {
      if (this.isEmpty(value) || isNaN(Number(value))) {
        return delimiter + 0;
      }
      value = String(value);
      let decNum = "";
      let point = ".";
      if (value.indexOf(point) != -1) {
        decNum = value.substr(value.indexOf(point));
        value = value.substring(0, value.indexOf(point));
      }
      let comma = ",";
      let len = value.length;
      let commaPoint = 3;
      if (len <= commaPoint) {
        return delimiter + value + decNum;
      }
      let numOfDeliimiter = len % commaPoint == 0 ? len / commaPoint - 1 : len / commaPoint;

      for (let i = 1; i <= numOfDeliimiter; i++) {
        let index = len - commaPoint * i;
        let previousTxt = value.slice(0, index);
        let behindTxt = value.slice(index);
        value = previousTxt + comma + behindTxt;
      }
      return delimiter + value + decNum;
    },

    // 入力カンマ処理 単価
    changeNumUnitPrice(val, index) {
      var unitPrice = commonUtil.zen2han(val.unitPrice).replaceAll(",", "").replaceAll("¥", "");
      if (!isNaN(Number(unitPrice))) {
        this.inputList[index].unitPrice = "¥" + commonUtil.formatToCurrency(Number(unitPrice));
      }
    },

    // 金額変更処理 実績数量
    changeNumAchieveQuantity(val, index) {
      var achieveQuantity = commonUtil.zen2han(val.achieveQuantity).replaceAll(",", "");
      // const point = ".";

      // if (achieveQuantity.indexOf(point) == -1) {
      //   achieveQuantity =
      //     achieveQuantity.length > 9 ? achieveQuantity.slice(0, -1) : achieveQuantity;
      // }

      if (!isNaN(Number(achieveQuantity))) {
        val.achieveQuantity = commonUtil.formatToCurrency(Number(achieveQuantity));
      }

      this.inputList[index].price = this.calcPrice(
        val.achieveQuantity,
        val.unitPrice,
        val.convertedValue,
        this.fiveInDiv
      );
    },

    getPrice(val, index) {
      this.inputList[index].price = this.calcPrice(
        val.achieveQuantity,
        val.unitPrice,
        val.convertedValue,
        this.fiveInDiv
      );

      return this.inputList[index].price;
    },

    // 金額計算処理
    calcPrice(achieveQuantity, unitPrice, convertedValue, fiveInDiv) {
      var qty = commonUtil.zen2han(achieveQuantity).replaceAll(",", "");
      var price = commonUtil.zen2han(unitPrice).replaceAll(",", "").replaceAll("¥", "");
      var factor = commonUtil.zen2han(convertedValue).replaceAll(",", "");
      const maxPrice = 999999999;
      if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
        let amount;
        switch (fiveInDiv) {
          case "01":
            // 四捨五入
            amount = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            amount = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            amount = Math.floor(Number(qty * price * factor));
            break;
          default:
            amount = Math.round(Number(qty * price * factor));
        }
        if (amount > maxPrice) {
          amount = maxPrice;
        }
        return "¥" + commonUtil.formatToCurrency(amount);
      } else {
        return "";
      }
    },

    // 金額計算処理
    calcPriceMaxCheck(achieveQuantity, unitPrice, convertedValue, fiveInDiv) {
      var qty = commonUtil.zen2han(achieveQuantity).replaceAll(",", "");
      var price = commonUtil.zen2han(unitPrice).replaceAll(",", "").replaceAll("¥", "");
      var factor = commonUtil.zen2han(convertedValue).replaceAll(",", "");
      if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
        let amount;
        switch (fiveInDiv) {
          case "01":
            // 四捨五入
            amount = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            amount = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            amount = Math.floor(Number(qty * price * factor));
            break;
          default:
            amount = Math.round(Number(qty * price * factor));
        }
        return amount;
      } else {
        return 0;
      }
    },

    // 行追加されたものか判定
    isAddRow(val) {
      return val == "9999999999";
    },

    // スポットか
    isCalcSpot(val) {
      return val == "99";
    },

    // 指数表記変換
    toPlainValue(rawVal) {
      let splitVal = rawVal.toString().split("e");

      if (splitVal.length === 1) return splitVal[0];

      let period = splitVal[0].indexOf(".");

      if (0 < period) splitVal[1] -= period;

      return new Number(rawVal).toFixed(Math.abs(splitVal[1]));
    },

    // 小数点付き全角文字を半角に変換する
    convertedValChange(item) {
      if (this.isEmpty(item.convertedValue)) {
        return;
      }
      let val = String(item.convertedValue);
      item.convertedValue = this.toPlainValue(
        commonUtil.zen2han(val.replaceAll("．", ".").replaceAll("¥", ""))
      );
    },

    // 金額最大値チェック
    checkMaxAmount(achieveQuantity, unitPrice, convertedValue) {
      var qty = commonUtil.zen2han(achieveQuantity).replaceAll(",", "");
      var price = commonUtil.zen2han(unitPrice).replaceAll(",", "").replaceAll("¥", "");
      var factor = commonUtil.zen2han(convertedValue).replaceAll(",", "");
      const maxPrice = 999999999;
      if (!isNaN(Number(qty)) && !isNaN(Number(price)) && !isNaN(Number(factor))) {
        let sum;
        switch (this.fiveInDiv) {
          case "01":
            // 四捨五入
            sum = Math.round(Number(qty * price * factor));
            break;
          case "02":
            // 切り上げ
            sum = Math.ceil(Number(qty * price * factor));
            break;
          case "03":
            // 切り捨て
            sum = Math.floor(Number(qty * price * factor));
            break;
          default:
            sum = Math.round(Number(qty * price * factor));
        }
        let isValid = sum <= maxPrice;

        if (!isValid) {
          return i18n.tc("check.chk_limitPriceAct");
        }
      }
      return true;
    },

    // 数字のカンマ、¥マークの除去
    replaceNum(value) {
      if (value !== undefined && value !== null && value !== "") {
        value = String(value);
        value = value.replaceAll(",", "").replaceAll("¥", "");
      }

      return value;
    },

    // マスタ情報セット
    setMasterDate(item) {
      let ginesuOffice = item.officeSalesList.filter((val) => {
        return val.sid == item.billingSubject;
      })[0];
      let ginesuSouko = item.warehouseList.filter((val) => {
        return val.sid == item.billingSubject;
      })[0];
      item.officeSales = ginesuOffice;
      item.warehouseCd = ginesuSouko;
    },

    /**
     * カンマ付きの数字に変換する
     */
    parseAmountFormat(value, index) {
      if (!value || value.unitPrice == null) {
        return value;
      }
      let inputPrice = String(value.unitPrice);
      let isDecimal = 1 < inputPrice.split(".").length;
      let decimal;
      if (isDecimal) {
        decimal = inputPrice.split(".")[1];
      }
      inputPrice = inputPrice.split(".")[0];
      inputPrice = commonUtil.zen2han(inputPrice);
      let enInclude = inputPrice.includes("¥", 0);
      if (enInclude) {
        inputPrice = inputPrice.slice(1);
      }
      if (isNaN(Number(inputPrice.replaceAll(",", "")))) {
        return;
      }
      inputPrice = String(Number(inputPrice.replaceAll(",", "")));
      let delimiter = ",";
      let len = inputPrice.length;
      let delimiterPoint = 3;
      if (len >= delimiterPoint) {
        let numOfDeliimiter =
          len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

        for (let i = 1; i <= numOfDeliimiter; i++) {
          let index = len - delimiterPoint * i;
          let previousTxt = inputPrice.slice(0, index);
          let behindTxt = inputPrice.slice(index);
          inputPrice = previousTxt + delimiter + behindTxt;
        }
      }
      let price = this.strIns(inputPrice, 0, "¥");
      if (isDecimal && decimal != 0) {
        price = this.strIns(price, price.length, "." + decimal);
      }
      this.inputList[index].unitPrice = price;
      value.unitPrice = price;
      this.inputList[index].expireDateList[0].unitPrice = price;
    },

    changeUnitPriceClassContentsTxt(target, event) {
      setTimeout(function () {
        target.unitPriceClassContents = event;
      }, 1);
    },

    changeUnitPriceTxt(target, event, index) {
      new Promise((resolve) => {
        setTimeout(function () {
          target.unitPrice = event;
          resolve();
        }, 1);
      }).then(() => {
        this.changeNumUnitPrice(target, index);
      });
    },

    changeAchieveQuantityTxt(target, event, index) {
      new Promise((resolve) => {
        setTimeout(function () {
          target.achieveQuantity = event;
          resolve();
        }, 1);
      }).then(() => {
        this.changeNumAchieveQuantity(target, index);
      });
    },

    changeConvertedValueTxt(target, event) {
      new Promise((resolve) => {
        setTimeout(function () {
          target.convertedValue = event;
          resolve();
        }, 1);
      }).then(() => {
        this.convertedValChange(target);
      });
    },
    changeTransferRateTxt(target, event) {
      new Promise((resolve) => {
        setTimeout(function () {
          target.transferRate = event;
          resolve();
        }, 1);
      }).then(() => {
        if (event !== "") {
          let num = Number(commonUtil.zen2han(event));
          target.transferRate = isNaN(num) ? event : num;
          event = isNaN(num) ? event : num;
        }
      });
    },
    setBaseCd() {
      this.baseCd = this.loginUserOffice.length !== 0 ? this.loginUserOffice[0].baseCd : "";
    },
    isDisabledOffice(item) {
      if (item.billingItems == "") {
        return true;
      }
      let result = this.officeSalesList.filter((obj) => {
        return obj.text == this.baseCd;
      });
      return result.length !== 0;
    },
    sumPrice() {
      let total = 0;
      for (let i = 0; i < this.inputList.length; i++) {
        total = total + Number(this.replaceNum(this.inputList[i].price));
      }
      return "¥" + commonUtil.formatToCurrency(total);
    },
    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },
    // 業務マスタ取得ＡＰＩ
    getBusinessMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 業務No
      config.params.businessNo = this.billingBussinessNoSelected;
      // マスタ取得区分
      config.params.isMst = "0";

      // console.debug("getBusinessMst() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_BUSINESS, config)
          .then((response) => {
            // console.debug("getBusinessMst() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let fiveInDiv;
              jsonData.resIdv.businesses.forEach((row) => {
                fiveInDiv = row.fiveInDiv;
              });
              this.fiveInDiv = fiveInDiv;
              resolve("業務マスタ取得APIレスポンス", response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * システム情報の最新を明細に反映する
     */
    refreshSystemData() {
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // 請求No
      config.params.businessNo = this.billingBussinessNoSelected;
      // 請求月度
      config.params.yyyyMm = this.formatDateDelimiter(this.billingMonth, "/");

      const spotData = this.inputList.filter((element) => {
        return this.isCalcSpot(element.calculationClassification) === true;
      });

      // console.debug("refreshSystemData() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // TODO API名
          .secureGet(appConfig.API_URL.BIZ_BIL_ACTUAL_SYSTEM_UPDATE, config)
          .then((response) => {
            // console.debug("refreshSystemData() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              const delList = [];
              jsonData.resIdv.invoiceActDetail.forEach((row) => {
                if (list.length == 0) {
                  this.verSid = row.verSid; // バージョンSID
                  this.invListNo = row.invListNo; // 請求伝票No
                }
                let actQuantity =
                  row.unitCd === "04" && row.calculateDiv === "03"
                    ? Number(row.actQuantity).toFixed(3)
                    : Number(row.actQuantity);
                let actQuantityWithComma = this.parseNumberFormat(actQuantity, "");
                let isSpot = this.isCalcSpot(row.calculateDiv);
                let souko = [];
                let office = [];
                let subject = [];
                let billingSubject = row.gkkamo;
                if (isSpot) {
                  let target = spotData.filter((element) => {
                    return element.billingItems === row.gkcode;
                  });
                  if (0 < target.length) {
                    souko = target[0].warehouseList;
                    office = target[0].officeSalesList;
                    subject = target[0].billingSubjectList;
                    billingSubject =
                      0 < target[0].billingSubjectList.length
                        ? target[0].billingSubjectList[0].value
                        : row.gkkamo;
                  }
                }
                list.push({
                  verSid: row.verSid, // バージョンSID
                  invoiceItemSid: row.invoiceItemSid, // 請求項目ID
                  unitPriceSid: row.unitPriceSid, // 単価SID
                  billingItems: row.gkcode, // 請求項目CD
                  billingSubject: billingSubject, // 計上科目CD
                  unitPriceClassificPrice: row.unitPriceCd, // 単価コード
                  unitPriceClassContents: row.unitPriceDetail, // 単価内容
                  unitPriceGr: row.unitPriceGroup, // 単価Gr
                  periodDiv: row.periodDiv, // 期区分
                  unitPrice: this.parseNumberFormat(row.unitPrice, "¥"), // 単価
                  unitPriceCd: row.unitCd, // 単位コード
                  achieveQuantity: actQuantityWithComma, // 実績数量
                  price: this.calcPrice(
                    row.actQuantity,
                    row.unitPrice,
                    row.unitPriceFactor,
                    this.fiveInDiv
                  ), // 金額
                  convertedValue: row.unitPriceFactor, // 単価係数
                  officeSales: this.isEmpty(row.appOfficeSid) ? "" : row.appOfficeSid.trim(), // 営業所SID
                  warehouseCd: this.isEmpty(row.appWarehouseSid) ? "" : row.appWarehouseSid.trim(), // 倉庫SID
                  taxClassification: row.taxDiv, // 税区分
                  calculationClassification: row.calculateDiv, // 計算区分
                  deleteFlg: 0, // 削除フラグ
                  updateDatetime: row.updateDatetime, // 更新日時
                  invListNo: row.invListNo, // 請求伝票No
                  autoNo: row.autoNo, // 連番
                  billingSubjectList: subject,
                  officeSalesList: office,
                  warehouseList: souko,
                  firstFlg: true,
                  addItem: false, // 実績数量以外編集不可
                  transferRate: row.giroPercent == null ? "" : row.giroPercent,
                  fiveInDiv: this.fiveInDiv,
                });
              });
              jsonData.resIdv.invoiceActDeleteList.forEach((row) => {
                delList.push({
                  verSid: row.verSid, // バージョンSID
                  yyyyMm: row.yyyyMm, // 月度
                  invoiceItemSid: row.invoiceItemSid, // 請求項目SID
                  periodDiv: row.periodDiv, // 期区分
                  unitPriceSid: row.unitPriceSid, // 単価SID
                  autoNo: row.autoNo, // スポット用の連番
                  updateDatetime: row.updateDatetime, // 請求実績更新日付
                });
              });
              this.inputList = [];
              this.deleteList = [];
              this.inputList = list;
              this.invoiceActDeleteList = delList;
              this.isRefreshed = true;
              resolve(response);

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.bilActualFlg = false;
              this.editing = false;
              this.errorFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.bilActualFlg = false;
            this.editing = false;
            this.errorFlg = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {
    shownHeaders() {
      return this.headerItems.filter((h) => {
        if (h.value === "delete") {
          return this.editing;
        } else {
          return true;
        }
      });
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    billingMonthCal(val) {
      this.billingMonth = this.formatDate(val);
    },
    inputList: {
      handler() {
        this.sumPriceTxt = this.sumPrice();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.align-right {
  text-align: right;
}

.align-right ::v-deep {
  input {
    text-align: right;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.font-medium {
  font-size: medium;
}

.line {
  white-space: pre-wrap;
}

.v-data-table {
  white-space: pre-line;
}

.pull-min-width ::v-deep {
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 15px;
  }
}
</style>
